import React, { useEffect, useState } from "react";
import "./popup.css";
import Modal from "@mui/material/Modal";
import { RxCross1 } from "react-icons/rx";
import CardItems from "../Components/CommonComponents/CardItems";
import { getCart, getCustomerId } from "../APIComponents/CartsApi";
import { useCart } from "./CartContext";
import { Link } from "react-router-dom";

export default function CartTabPopup({ open, onClose }) {
  const updateCartCount = useCart();
  const [cartItems, setCartItems] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCarts = async () => {
      const customerId = getCustomerId();
      console.log("Customer ID used:", customerId);
      try {
        const response = await getCart(customerId);
        setCartItems(response?.data || []);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching cart items:", error);
        setLoading(false);
      }
    };

    fetchCarts();
  }, []);

  const calculateSubtotal = (items) => {
    return items.reduce((total, item) => {
      const rawPrice = item.productDetails?.productPrice || '0';
      const cleanedPrice = rawPrice.replace(/[^0-9.-]+/g, '');
      const productPrice = parseFloat(cleanedPrice) || 0;
      return total + productPrice * item.Quantity;
    }, 0).toFixed(2); 
  };

  const subtotal = calculateSubtotal(cartItems);

  const handleCartUpdate = () => {
    const fetchCarts = async () => {
      const customerId = getCustomerId();
      try {
        const response = await getCart(customerId);
        setCartItems(response.data);
        updateCartCount(); 
      } catch (error) {
        console.error("Error fetching cart items:", error);
      }
    };

    fetchCarts();
  };

  const closePopup = () => {
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="popup-cart">
        <div className="d-flex justify-content-between">
          <span className="tick-icon">
            <svg
              width="36"
              height="36"
              viewBox="0 0 36 36"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_642_54)">
                <path
                  d="M13.7198 27.6898L3.28977 17.2698C3.12595 17.0785 3.04034 16.8324 3.05006 16.5807C3.05978 16.329 3.16412 16.0903 3.34221 15.9122C3.5203 15.7341 3.75904 15.6298 4.01072 15.6201C4.2624 15.6103 4.50847 15.6959 4.69977 15.8598L13.6998 24.8598L31.2898 7.28977C31.4811 7.12595 31.7271 7.04034 31.9788 7.05006C32.2305 7.05978 32.4692 7.16412 32.6473 7.34221C32.8254 7.5203 32.9298 7.75904 32.9395 8.01072C32.9492 8.2624 32.8636 8.50847 32.6998 8.69977L13.7198 27.6898Z"
                  fill="#524F4F"
                />
              </g>
              <defs>
                <clipPath id="clip0_642_54">
                  <rect width="36" height="36" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </span>
          {cartItems.length > 0 ? (
            <>
              <h2 className="modal-title">Item Added to Your Cart</h2>
              <button className="btn text-end cross-button" onClick={onClose}>
                <RxCross1 />
              </button>
            </>
          ) : (
            !loading && (
              <div className="shopping-cart-heading">
                <div className="d-flex">
                  <h2 className="shopping-cart-title">Shopping Cart</h2>
                  <button className="btn text-end cross-button" onClick={onClose}>
                    <RxCross1 />
                  </button>
                </div>
                <div className="shopify-cart-body">
                  <p className="empty-cart-title">Your Cart Is Empty</p>
                  <Link to="/all-products" onClick={onClose}>
                    <button type="button" className="continue-shopping-button">
                      Continue Shopping
                    </button>
                  </Link>
                </div>
              </div>
            )
          )}
        </div>
        {loading ? (
          <div className="text-center mt-5">
            <div className="spinner"></div>
            <p>Loading...</p>
          </div>
        ) : (
          cartItems.length > 0 && (
            <div className="product-area gap-3 mt-5">
              {cartItems.map((item) => (
                <CardItems
                  key={item._id}
                  data={item}
                  onClose={onClose}
                  setCartItems={setCartItems}
                  subtotal={subtotal}
                  closePopup={closePopup}
                  handleCartUpdate={handleCartUpdate}
                  loading={loading}
                />
              ))}
            </div>
          )
        )}
      </div>
    </Modal>
  );
}
