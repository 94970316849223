import React, { useEffect, useState } from "react";
import CustomerProfile from "./CustomerProfile";
import "./CustomerProfile.css";
import { useLocation } from "react-router-dom";
import { SingleOrderDetails } from "../customerDashboardApi/PostApi";
import Spinner from "react-bootstrap/Spinner";
import Header from "../header/Header";

export default function OrderDetails() {
  const location = useLocation();
  const [orderDetails, setOrderDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchOrderDetails = async () => {
      const queryParams = new URLSearchParams(location.search);
      const orderIdFromUrl = queryParams.get("orderId");
      if (orderIdFromUrl) {
        try {
          const response = await SingleOrderDetails(orderIdFromUrl);
          console.log(response, "rpodjasidjmiol")
          setOrderDetails(response.order);
          setLoading(false);
        } catch (error) {
          setError(error);
          setLoading(false);
        }
      }
    };
    fetchOrderDetails();
  }, [location.search]);

  if (loading) {

    <Spinner animation="border" role="status" className="loading-spinner">
      <span className="visually-hidden">Loading...</span>
    </Spinner>

  }
  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const productDetails = orderDetails?.productDetails || [];

  const subtotal = productDetails.reduce((acc, productDetail) => {
    const productPrice = parseFloat(productDetail.product?.productPrice) || 0;
    const quantity = parseInt(productDetail.quantity) || 0;
    return acc + (productPrice * quantity);
  }, 0);

  const discount = parseFloat(productDetails[0]?.product?.discount) || 0;
  const taxPercentage = parseFloat(productDetails[0]?.product?.taxPercentage) || 0;
  const escFees = parseFloat(productDetails[0]?.product?.escFees) || 0;

  // Calculate tax amount
  const taxAmount = (subtotal * (taxPercentage / 100)) || 0;

  // Calculate total amount
  const totalAmount = subtotal - discount + taxAmount + escFees;

  return (
 <>
        <CustomerProfile />
        <Header />
      <section className="order-details">
        <div className="detail-box">
          <div className="detail-headdimg">
            <h3>Order Details</h3>
          </div>
          <div className="detail-contant">
            <p>Order Number: {orderDetails?._id}</p>
            <p>
              Order Date:{" "}
              {orderDetails?.orderDate
                ? new Date(orderDetails.orderDate).toLocaleDateString()
                : "N/A"}
            </p>
            <p>Payment Status: {orderDetails?.paymentStatus}</p>
            <p>Delivery Status: {orderDetails?.deliveryStatus}</p>
            <p>Delivery Method: {orderDetails?.deliveryMethod}</p>
          </div>
        </div>
        <div className="product-box">
          {productDetails.map((productDetail, index) => (
            <div className="product-box-container" key={index}>
            <div className="product-detail-top">  <div className="Product-heading-img-box">
                <h3>Product</h3>
                <div className="product-image">
                  <div className="img-product">
                    <img
                      src={productDetail.product?.productImage}
                      alt={productDetail.product?.productTitle}
                    />
                  </div>
                  <p>{productDetail.product?.productDescription}</p>
                </div>
              </div>
              <div className="Product-heading-img requested">
                <h3>Requested Shipping Date</h3>
                <div className="product-dtl">
                  <p>&nbsp;</p>
                </div>
              </div>
              <div className="Product-heading-img">
                <h3>Status</h3>
                <div className="product-dtl">
                  <p>{orderDetails?.deliveryStatus}</p>
                </div>
              </div>
              <div className="Product-heading-img">
                <h3>Qty</h3>
                <div className="product-dtl">
                  <p>{productDetail.quantity}</p>
                </div>
              </div>
              <div className="Product-heading-img">
                <h3>Total</h3>
                <div className="product-dtl">
                  <p>{productDetail.product?.productPrice}</p>
                </div>
              </div>
            </div>
            </div>
          ))}
          <div className="total-box">
            <div className="amount-box-container">
              <div className="amount-box">
                <div className="total-text">
                  <p>Subtotal:</p>
                </div>
                <div className="total-text">
                  {/* <p>${orderDetails.totalAmount}</p> */}
                </div>
              </div>
              <div className="amount-box">
                <div className="total-text">
                  <p>Discount:</p>
                </div>
                <div className="total-text">
                  <p>${discount.toFixed(2)}</p>
                </div>
              </div>
              <div className="amount-box">
                <div className="total-text">
                  <p>Tax:</p>
                </div>
                <div className="total-text">
                  <p>${taxAmount.toFixed(2)}</p>
                </div>
              </div>
              <div className="amount-box">
                <div className="total-text">
                  <p>Shipping Tax:</p>
                </div>
                <div className="total-text">
                  <p>${escFees.toFixed(2)}</p>
                </div>
              </div>
              <div className="amount-box">
                <div className="total-text">
                  <p>Total:</p>
                </div>
                <div className="total-text">
                  {/* <h6>${orderDetails.totalAmount}</h6> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="detail-box address">
          <div className="detail-headdimg">
            <h3>Additional Details</h3>
          </div>
          <div className="detail-contant">
            <p>Comment: {orderDetails?.comment}</p>
            <p>Notes: {orderDetails?.notes}</p>
            <p>Customer Name: {orderDetails?.customerName}</p>
            <p>Contact Info: {orderDetails?.contactInfo}</p>
            <p>Shipping Address: {orderDetails?.shippingAddress}</p>
            <p>Billing Address: {orderDetails?.billingAddress}</p>
          </div>
        </div> */}
      </section>
    </>
  );
}
