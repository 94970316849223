import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import HomePage from "./HomePage";
import ShowAllProduct from "../CustomerDashboard/Components/CommonComponents/ShowAllProduct";
import Header from "../CustomerDashboard/Components/CommonComponents/Header";
import Footer from "../CustomerDashboard/Components/CommonComponents/Footer";
import ViewCart from "../CustomerDashboard/Components/CommonComponents/CartComponents/ViewCart";
import SingleProductPage from "../CustomerDashboard/Components/CommonComponents/SingleProductPage";
import Contact from "../CustomerDashboard/Components/CommonPages/Contact";
import CustomerRegistration from "../CustomerAdminDashboard/customerRegister/CustomerRegistration";
import CustomerLogin from "../CustomerAdminDashboard/customerLogin/CustomerLogin";
import CustomerOtp from "../CustomerAdminDashboard/CustomerOTP/CustomerOtp";
import SetCustomerPassword from "../CustomerAdminDashboard/changePassword/SetCustomerPassword";
import CustomerProfile from "../CustomerAdminDashboard/customerDashboardProfile/CustomerProfile";
import Profile from "../CustomerAdminDashboard/customerDashboardProfile/Profile";
import OrderList from "../CustomerAdminDashboard/customerDashboardProfile/OrderList";
import OrderDetails from "../CustomerAdminDashboard/customerDashboardProfile/OrderDetails";
import About from "../CustomerDashboard/Components/CommonPages/About";
import Services from "../CustomerDashboard/Components/CommonPages/Services";
import WhyUltraClear from "../CustomerDashboard/Components/CommonPages/WhyUltraClear";
import GetStarted from "../CustomerDashboard/Components/CommonPages/GetStarted";
import ForgotPassword from "../CustomerAdminDashboard/forgtoPassword/ForgotPassword";
import UpdateProfile from "../CustomerAdminDashboard/customerDashboardProfile/UpdateProfile";
import Error from "../Error";
import Thankyou from "../CustomerDashboard/Components/CommonComponents/ThankyouOrder/Thankyou";
import { CartProvider } from "../CustomerDashboard/PopupComponent/CartContext";
import CheckoutNew from "../CustomerDashboard/Components/CommonComponents/CartComponents/CheckoutComponents/CheckoutNew";
import CheckoutData from "../CustomerDashboard/Components/CommonComponents/CartComponents/CheckoutComponents/CheckoutData";
import MainCheckout from "../CustomerDashboard/Components/CommonComponents/CartComponents/CheckoutComponents/MainCheckout";
export default function RouteManage() {
  const location = useLocation();
  const isAdminCustomerRegisterRoute = location.pathname.startsWith("/admin/");
  return (
    <>
      <CartProvider>
        {!isAdminCustomerRegisterRoute && <Header />}
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/all-products" element={<ShowAllProduct />} />
          <Route path="/Checkout" element={<MainCheckout />} />
          <Route path="/CheckoutData" element={<CheckoutData />} />
          <Route path="/viewCart" element={<ViewCart />} />
          <Route
            path="/viewSingleProduct/:id"
            element={<SingleProductPage />}
          />
          <Route path="/About-us" element={<About />} />
          <Route path="/Services" element={<Services />} />
          <Route path="/Why-us" element={<WhyUltraClear />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/get-started" element={<GetStarted />} />
          <Route path="/Thankyou" element={<Thankyou />} />
          <Route
            path="/admin/customerRegister"
            element={<CustomerRegistration />}
          />
          <Route path="/admin/customerLogin" element={<CustomerLogin />} />
          <Route path="/admin/customerOtp" element={<CustomerOtp />} />
          <Route path="/admin/setPassword" element={<SetCustomerPassword />} />
          <Route path="/admin/customerProfile" element={<CustomerProfile />} />
          <Route path="/admin/profile" element={<Profile />} />
          <Route path="/admin/orderList" element={<OrderList />} />
          <Route path="/admin/orderDetails" element={<OrderDetails />} />
          <Route path="/admin/forgot" element={<ForgotPassword />} />
          <Route path="/admin/UpdateCustomer" element={<UpdateProfile />} />
          <Route path="/*" element={<Error />} />
        </Routes>
        {!isAdminCustomerRegisterRoute && <Footer />}
      </CartProvider>
    </>
  );
}
