import React, { useState } from "react";
import "./Header.css";
import logo from "../../assets/img/logo.png";
import profileIcon from "../../assets/img/profile-ic.png";
import { useNavigate } from "react-router-dom";
import { FiLogOut } from "react-icons/fi";
import { Link } from "react-router-dom";
export default function Header() {
  const Navigate = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const userToken = localStorage.getItem("userToken");
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem("userToken");
    Navigate("/");
    setIsDropdownOpen(false);
  };

  return (
    <div>
      <header className="home">
        <div className="main-header-tag-title">
          <div className="logo">
            <Link to="/">
              <img src={logo} className="image-logo-hide" alt="logo" />
            </Link>
          </div>
          <div className="profile-icon">
            <img
              src={profileIcon}
              alt="profile-icon"
              onClick={toggleDropdown}
              style={{ cursor: "pointer" }}
            />
            {isDropdownOpen &&
              userToken && (
                <div className="dropdown-menu">
                  <button className="logout-button" onClick={handleLogout}>
                  <FiLogOut /> Logout
                  </button>
                </div>
              )}
          </div>
        </div>
      </header>
    </div>
  );
}
