import React, { useEffect, useState } from "react";
import "./common.css";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import { GetProductAPI } from "../../APIComponents/ProductAPIs/GetAPI";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from "react-router-dom";
import { AddToCart } from "../../APIComponents/CartsApi";
import { Toaster, toast } from "react-hot-toast";
import { getCustomerId } from "../../APIComponents/CartsApi";
import { useCart } from "../../PopupComponent/CartContext";
import CartTabPopup from "../../PopupComponent/CartTabPopup";
export default function LatestProduct() {
  const { updateCartCount } = useCart();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [isCartPopupOpen, setCartPopupOpen] = useState(false);
  const [cartItem, setCartItem] = useState(null); 
  console.log(products, "products");
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
    prevArrow: <></>,
    nextArrow: <></>,
  };
  // ...................GETALLPRODUCTAPI.......................
  const getProduct = async () => {
    try {
      const productData = await GetProductAPI();
      console.log(productData,"kkkkkkkkkkkk")
      setProducts(productData);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getProduct();
  }, []);
  // ................SHOWALLPRODUCTAPI.................
  const ShowAllProducts = async () => {
    navigate("/all-products?latest=true");
  };
  // ...............ADDTOCART.....................

  const handleAddToCart = async (productId) => {
    try {
      const customerId = getCustomerId();
      console.log("Customer ID used:", customerId);
      const response = await AddToCart(customerId, productId, 1);
      console.log("API Response:", response);
      if (response && response.status === 200) {
        toast.success(response.message || "Product is Added to Cart");
        updateCartCount();
        setCartItem(productId); 
        setCartPopupOpen(true);
      }
    } catch (error) {
      console.error("Error adding to cart:", error);
      toast.error("Error adding product to cart");
    }
  };

  const viewProduct = (productId) => {
    navigate(`/viewSingleProduct/${productId}`);
  };

  return (
    <>
      <Toaster position="top-right" reverseOrder={false} />
      <div className="product-list-home">
        <div className="container">
          <div className="latest-product-area mt-5">
            <div className="latest-header">
              <h3 className="latest-heading">{t("Our Latest Products")}</h3>
            </div>
            <div className="latest-product-box row slider-container">
              <Slider {...settings}>
                {products?.map((product) => (
                  <div className="col-md-3 col-3" key={product._id}>
                    <div className="latest-product-co">
                      <div className="product-box">
                        <img
                          src={product.productImage}
                          alt={product.productTitle}
                          onClick={() => viewProduct(product._id)}
                          style={{ maxWidth: "68%", height: "170px" }}
                        />
                        <h4 className="latest-product-title">
                          {t(product.productTitle)}
                        </h4>
                        <p className="price-product">
                          {t(product.productPrice)}
                        </p>
                        <button
                          className="btn btn-primary"
                          onClick={() => handleAddToCart(product._id)}
                          disabled={product.productStockAvailable <= 0}
                        >
                          {product.productStockAvailable <= 0
                            ? t("Out of Stock")
                            : t("Add to Cart")}
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
            <div className="shop-button mt-4 text-center">
              <button
                type="button"
                className="btn btn-primary"
                onClick={ShowAllProducts}
              >
                {t("Show All")}
              </button>
            </div>
          </div>
        </div>
      </div>
      {isCartPopupOpen && (
        <CartTabPopup
          open={isCartPopupOpen}
          onClose={() => setCartPopupOpen(false)}
        />
      )}
    </>
  );
}
