import axios from "axios";
import { ObjectId } from "bson";
const url = process.env.REACT_APP_BASE_URL;
// // const url = "https://7f69-122-160-167-80.ngrok-free.app";
// const headers = {
//   "Content-type": "application/json",
// };

// const url='http://localhost:8001'
// const headers = {
//   "ngrok-skip-browser-warning": "true",
// };
const headers = {
  'Content-Type' : "application/json"
}

// ........................Add to Cart..............................
export const AddToCart = async (customerId, productId, quantity) => {
  try {
    console.log(customerId, "customerid");
    console.log(productId, "productid");
    const response = await axios.post(
      `${url}/cart`,
      {
        customerId,
        productId,
        quantity,
      },
      { headers }
    );

    console.log(response.data, "backend from server");
    return response.data;
  } catch (error) {
    console.log(error, "error");
    throw error;
  }
};
// ...................Update Cart ................................
export const UpdateCart = async (CustomerId, productId, quantity) => {
  try {
    const response = await axios({
      method: "put",
      url: `${url}/update-cart/${CustomerId}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        productId,
        quantity,
      },
    });

    console.log(response, "update response");
    return response;
  } catch (error) {
    console.error(error.message, "error");
    throw error;
  }
};
// ..................delete cart...........................
export const DeleteCart = async (customerId, productId) => {
  try {
    const response = await axios(
      {
        method: "delete",
        url: `${url}/delete-cart`,
        data: { customerId, productId },
      },
      {
        headers: headers,
      }
    );

    console.log(response, "delete respone");
    return response;
  } catch (e) {
    console.log(e.message, "errror");
  }
};

// ..................Generate Cusotmer ID....................
export const getCustomerId = () => {
  let customerId = localStorage.getItem("customerId");
  console.log("Current customerId from localStorage:", customerId);
  if (!customerId) {
    const customerId = () => new ObjectId().toString();
    const data = customerId();
    console.log("Generated new customerId:", data);
    localStorage.setItem("customerId", data);
  }
  return customerId;
};
// ...................Get Cart Details...................
export const getCart = async (customerId) => {
  try {
    console.log(customerId,"getCartCustomerId")
    const response = await axios.get(`${url}/cartDetail/${customerId}`,{
      headers:headers
    });
    console.log(response.data, "response from server");
    return response.data;
  } catch (error) {
    console.log(error, "error");
    return { data: [] };
  }
};
