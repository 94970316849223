// import React, { useEffect, useState } from "react";
// import "./CustomerProfile.css";
// import CustomerProfile from "./CustomerProfile";
// import profileimg from "../../assets/404-sad-img.png";
// import { UpdateCustomer } from "../customerDashboardApi/PostApi";
// import { CustomerProfileAPi } from "../customerDashboardApi/PostApi";
// import { useNavigate } from "react-router-dom";

// export default function UpdateProfile() {
//   const navigate = useNavigate();
//   const [email, setEmail] = useState("");
//   const [form, setForm] = useState({
//     image: null,
//     name: "",
//     phoneNumber: "",
//     email: "",
//     address: "",
//   });
//   const [imagePreview, setImagePreview] = useState(null);

//   // Get email from local storage
//   useEffect(() => {
//     const emailFromStorage = localStorage.getItem("userEmail");
//     if (emailFromStorage) {
//       setEmail(emailFromStorage);
//     } else {
//       console.error("No email found in local storage");
//     }
//   }, []);

//   // Fetch and set customer profile data
//   useEffect(() => {
//     if (email) {
//       const fetchProfileData = async () => {
//         try {
//           const data = await CustomerProfileAPi(email);
//           setForm({
//             image: data.userData.Image || null,
//             name: data.userData.CustomerName || "",
//             phoneNumber: data.userData.Contact || "",
//             email: data.userData.Email || "",
//             address: data.userData.Address || "",
//           });
//           if (data.userData.Image) {
//             setImagePreview(data.userData.Image);
//           }
//         } catch (error) {
//           console.error("Error fetching customer profile:", error);
//         }
//       };

//       fetchProfileData();
//     }
//   }, [email]);

//   const handleChange = (e) => {
//     const { name, value, files } = e.target;
//     if (name === "image") {
//       const file = files[0];
//       setForm({ ...form, image: file });

//       // Create a URL for the image preview
//       const imageUrl = URL.createObjectURL(file);
//       setImagePreview(imageUrl);
//     } else {
//       setForm({ ...form, [name]: value });
//     }
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       await UpdateCustomer(form, email);
//       navigate("/admin/profile");
//     } catch (error) {
//       console.error("Error updating profile:", error);
//     }
//   };

//   return (
//     <>
//       <CustomerProfile />
//       <div className="login_register">
//         <div className="login_inner profile">
//           <div className="profile-image">
//             <div className="profile-image-container">
//               <img src={imagePreview || form.image || profileimg} alt="Profile" />
//             </div>
//             <input
//               type="file"
//               name="image"
//               className="image-upload"
//               onChange={handleChange}
//             />
//           </div>
//           <form className="form_box top-cl" onSubmit={handleSubmit}>
//             <div className="field_box">
//               <div className="field_inner">
//                 <input
//                   type="text"
//                   className="custom_form"
//                   name="name"
//                   placeholder="Customer Name"
//                   value={form.name}
//                   onChange={handleChange}
//                   required
//                 />
//               </div>
//               <div className="field_inner">
//                 <input
//                   type="email"
//                   className="custom_form"
//                   name="email"
//                   placeholder="Email"
//                   value={form.email}
//                   onChange={handleChange}
//                   required
//                 />
//               </div>
//             </div>
//             <div className="field_box no_botm">
//               <div className="field_inner">
//                 <input
//                   type="number"
//                   className="custom_form"
//                   name="phoneNumber"
//                   placeholder="(800) 555‑0100"
//                   value={form.phoneNumber}
//                   onChange={handleChange}
//                   required
//                 />
//               </div>
//               <div className="field_inner">
//                 <label>Address</label>
//                 <textarea
//                   className="custom_form"
//                   name="address"
//                   placeholder="Address"
//                   value={form.address}
//                   onChange={handleChange}
//                   required
//                 />
//               </div>
//             </div>
//             <div className="fild_box">
//               <div className="fild_inner">
//                 <button type="submit" className="signup_btn">
//                   Submit
//                 </button>
//               </div>
//             </div>
//           </form>
//         </div>
//       </div>
//     </>
//   );
// }

import React, { useEffect, useState } from "react";
import "./CustomerProfile.css";
import { FiLogOut } from "react-icons/fi";
import profileIcon from "../../assets/img/profile-ic.png";
import CustomerProfile from "./CustomerProfile";
import profileimg from "../../assets/404-sad-img.png";
import { UpdateCustomer } from "../customerDashboardApi/PostApi";
import { CustomerProfileAPi } from "../customerDashboardApi/PostApi";
import { useNavigate } from "react-router-dom";
import { Toaster, toast } from "react-hot-toast";
export default function UpdateProfile() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const userToken = localStorage.getItem("userToken");
  const [form, setForm] = useState({
    image: null,
    name: "",
    phoneNumber: "",
    email: "",
    address: "",
  });
  const [imagePreview, setImagePreview] = useState(null);


  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem("userToken");
    navigate("/");
    setIsDropdownOpen(false);
  };

  // Get email from local storage
  useEffect(() => {
    const emailFromStorage = localStorage.getItem("userEmail");
    if (emailFromStorage) {
      setEmail(emailFromStorage);
    } else {
      console.error("No email found in local storage");
    }
  }, []);
  useEffect(() => {
    if (email) {
      const fetchProfileData = async () => {
        try {
          const data = await CustomerProfileAPi(email);
          setForm({
            image: data.userData.Image || null,
            name: data.userData.UserName || "",
            phoneNumber: data.userData.Mobile || "",
            email: data.userData.Email || "",
            address: data.userData.Address || "",
          });
          if (data.userData.Image) {
            setImagePreview(data.userData.Image);
          }
        } catch (error) {
          console.error("Error fetching customer profile:", error);
        }
      };
      fetchProfileData();
    }
  }, [email]);
  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "image") {
      const file = files[0];

      if (
        file &&
        !["image/svg+xml", "image/jpeg", "image/png"].includes(file.type)
      ) {
        toast.error("Only SVG, JPEG, PNG formats are allowed.");
        return;
      }

      setForm({ ...form, image: file });

      // Create a URL for the image preview
      const imageUrl = URL.createObjectURL(file);
      setImagePreview(imageUrl);
    } else {
      setForm({ ...form, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Form validation
    const errors = {};

    // Validate name
    if (!form.name) errors.name = "Customer Name is required";

    // Validate email
    if (!form.email) {
      errors.email = "Email is required";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(form.email)) {
      errors.email = "Invalid email format";
    }

    // Validate phone number
    if (!form.phoneNumber) {
      errors.phoneNumber = "Phone Number is required";
    } else if (
      !/^\+?(\d{1,4})?\s?\(?\d{1,4}\)?[\s.-]?\d{1,4}[\s.-]?\d{1,9}$/.test(
        form.phoneNumber
      )
    ) {
      errors.phoneNumber = "Invalid phone number format";
    }

    // Validate address
    if (!form.address) errors.address = "Address is required";

    // Display errors if any
    if (Object.keys(errors).length > 0) {
      // Log errors for debugging
      console.log("Validation errors:", errors);

      // Iterate over errors and display them as toast notifications
      for (const [key, message] of Object.entries(errors)) {
        toast.error(message);
      }
      return;
    }

    // Proceed with form submission
    try {
      await UpdateCustomer(form, email);
      toast.success("Profile updated successfully!");
      navigate("/admin/profile");
    } catch (error) {
      toast.error("Error updating profile. Please try again.");
      console.error("Error updating profile:", error);
    }
  };

  return (
    <>
      <CustomerProfile />
      <Toaster position="top-right" reverseOrder={false} />
      <section className="home">
        <div className="main-header-tag-title">
          <div className="Side-bar-center">Sales Dashboard</div>
          <div className="profile-icon">
            <img
              src={profileIcon}
              className="person-image"
              alt="profile-icon"
              onClick={toggleDropdown}
              style={{ cursor: "pointer" }}
            />
            {isDropdownOpen && userToken && (
              <div className="dropdown-menu">
                <button className="logout-button" >
                  <FiLogOut />
                  Logout
                </button>
              </div>
            )}
          </div>
        </div>
      </section>
      <div className="login_register">
        <div className="login_inner profile">
          <div className="profile-image">
            <div className="profile-image-container">
              <img
                src={imagePreview || form.image || profileimg}
                alt="Profile"
              />
            </div>
            <div className="attach-image">
              {" "}
              <input
                type="file"
                name="image"
                className="image-upload"
                onChange={handleChange}
              />
            </div>
          </div>
          <form className="form_box top-cl" onSubmit={handleSubmit}>
            <div className="field_box">
              <div className="field_inner">
                <input
                  type="text"
                  className="custom_form"
                  name="name"
                  placeholder="Customer Name"
                  value={form.name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="field_inner">
                <input
                  type="email"
                  className="custom_form"
                  name="email"
                  placeholder="Email"
                  value={form.email}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="field_box no_botm">
              <div className="field_inner">
                <input
                  type="tel"
                  className="custom_form"
                  name="phoneNumber"
                  placeholder="(800) 555‑0100"
                  value={form.phoneNumber}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="field_inner">
                <label>Address</label>
                <textarea
                  className="custom_form"
                  name="address"
                  placeholder="Address"
                  value={form.address}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="fild_box">
              <div className="fild_inner">
                <button type="submit" className="signup_btn">
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
