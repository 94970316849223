import React, { useEffect, useState } from "react";
import { Pagination } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ProductTwo from "../../../assets/products_2.jpg";
import Products from "../../../assets/product-ban.jpg";
import SearchProduct from "../../APIComponents/SearchAPI/SearchProduct";
import { FaCheck } from "react-icons/fa6";
import {
  GetProductAPI,
  LatestProductAPI,
} from "../../APIComponents/ProductAPIs/GetAPI";
import { Toaster, toast } from "react-hot-toast";
import { AddToCart } from "../../APIComponents/CartsApi";
import { getCustomerId } from "../../APIComponents/CartsApi";
import { useCart } from "../../PopupComponent/CartContext";
import CartTabPopup from "../../PopupComponent/CartTabPopup";
export default function ShowAllProduct() {
  const { updateCartCount } = useCart();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [page, setPage] = useState(1);
  const productsPerPage = 4;
  const [showLatest, setShowLatest] = useState(false);
  const [latestProducts, setLatestProducts] = useState([]);
  const [isCartPopupOpen, setCartPopupOpen] = useState(false);
  const [cartItem, setCartItem] = useState(null); 

  const getAllProduct = async () => {
    try {
      const data = await GetProductAPI();
      setAllProducts(data);
    } catch (error) {
      console.log(error);
    }
  };

  const getLatestProduct = async () => {
    try {
      const data = await LatestProductAPI();
      setLatestProducts(data.data.products);
    } catch (error) {
      console.log("Error fetching latest products:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const searchParams = new URLSearchParams(location.search);
      const latestParam = searchParams.get("latest");
      const queryParam = searchParams.get("query");

      if (latestParam === "true") {
        setShowLatest(true);
        await getLatestProduct();
      } else {
        setShowLatest(false);
        await getAllProduct();
      }

      if (queryParam) {
        setQuery(queryParam);
      } else {
        setQuery("");
      }
    };

    fetchData();
  }, [location.search]);

  // const handleAddToCart = (productId) => {

  //   dispatch(
  //     addToCart({
  //       productId: productId,
  //       quantity: 1,
  //     })
  //   );
  //   toast.success(t("Product added to cart"));
  // };

  const handleAddToCart = async (productId) => {
    try {
      const customerId = getCustomerId();
      console.log("Customer ID used:", customerId);

      const response = await AddToCart(customerId, productId, 1);
      console.log("API Response:", response);

      if (response && response.status === 200) {
        toast.success(response.message);
        updateCartCount();
        setCartItem(productId); 
        setCartPopupOpen(true);
      }
    } catch (error) {
      console.error("Error adding to cart:", error);
      toast.error("Error adding product to cart");
    }
  };
  const viewProduct = (productId) => {
    navigate(`/viewSingleProduct/${productId}`);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const totalPages = Math.ceil(
    (query?.length === 0 ? allProducts?.length : results?.length) /
      productsPerPage
  );

  const startIndex = (page - 1) * productsPerPage;
  const endIndex = startIndex + productsPerPage;

  const currentProducts =
    query?.length === 0
      ? allProducts?.slice(startIndex, endIndex)
      : results?.slice(startIndex, endIndex);

  useEffect(() => {
    const fetchData = async () => {
      const searchParams = new URLSearchParams(location.search);
      const queryParam = searchParams.get("query");

      if (!queryParam || queryParam.trim() === "") {
        setShowLatest(false);
        getAllProduct();
        setResults([]);
      } else {
        try {
          const response = await SearchProduct(queryParam);
          const responseData = response.data;
          if (responseData.status === 200) {
            if (responseData.product) {
              setResults(responseData.product);
            } else {
              setResults([{ message: response.data.product }]);
            }
          } else {
            toast.error(response.data.product);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
          setResults([{ message: "No Product Found" }]);
        }
      }
    };

    fetchData();
  }, [location.search]);

  return (
    <>
      <Toaster position="top-right" reverseOrder={false} />
      <div className="product-banner-main">
        <img src={Products} alt="Product" className="img-fluid" />
      </div>

      <div class="reliable-products-home">
        <div class="container">
          <div class="row">
            <div class="col-xl-6 col-md-12 col-sm-12 pro-service-left1">
              <h3 className="title-progress">
                Performance. Our products include
              </h3>
              <div class="box-pro-icon-cl">
                <div class="check-bx-iconc">
                  <h3 class="title-ic">
                    <FaCheck />
                    Diesel Exhaust Fluid{" "}
                  </h3>
                  <h3 class="title-ic">
                    <FaCheck />
                    Lubricants including Engine Oil(DEF)
                  </h3>
                </div>
                <div class="check-bx-icon">
                  <h3 class="title-ic">
                    <FaCheck />
                    Antifreeze
                  </h3>
                  <h3 class="title-ic">
                    <FaCheck />
                    Washer fluids
                  </h3>
                </div>
              </div>
              <div class="bottom-content">
                <p className="text-pro-cl">
                  We offer a wide range of OEM approved products that ensure
                  your vehicles have access to quality products in a timely
                  fashion to ensure efficiency, reduced costs, and top-notch{" "}
                </p>
              </div>
            </div>
            <div class="col-xl-6 col-md-12 col-sm-12 product-service-right">
              <img src={ProductTwo} alt="Product" className="img-fluid" />
            </div>
            <div className="product-btm-content">
              {" "}
              <p className="reliable-content">
                <strong> Diesel Exhaust Fluid (DEF):</strong> Our DEF helps
                reduce emissions and improve the efficiency of diesel engines.
                It is a high-purity urea solution that meets stringent industry
                standards, ensuring optimal performance and compliance with
                environmental regulations.
              </p>
              <p className="reliable-content">
                <strong>Antifreeze:</strong> Our antifreeze products protect
                your engine from freezing in cold temperatures and overheating
                in hot conditions. They are formulated to prevent corrosion and
                ensure the longevity of your engine components.
              </p>
              <p className="reliable-content">
                <strong>Engine Oil: </strong> We provide high-quality engine
                oils that are engineered to enhance the performance and
                longevity of your vehicle's engine. Our engine oils are
                available in various grades to suit different engine types and
                conditions, ensuring your engine runs smoothly and efficiently
                under all driving conditions.
              </p>
              <p className="reliable-content">
                <strong>Break Cleaners:</strong> Our break cleaners are
                formulated to remove grease, oil, and other contaminants from
                your vehicle's brake components, ensuring optimal performance
                and safety. They are designed to work quickly and effectively,
                leaving no residue and improving the lifespan of your braking
                system.
              </p>
              <p className="reliable-content">
                <strong>Environmental Friendliness:</strong> All our products
                are environmentally friendly and designed to minimize the
                ecological impact. Our lubricants and fluids are crafted to
                reduce emissions and improve fuel efficiency, supporting your
                commitment to sustainability.
              </p>
              <p className="reliable-content">
                We have partnered with Lubricants manufacturers who are OEM
                approved and producing vehicle fluids and lubricants that can
                withstand extreme conditions while ensuring your vehicles
                operate at peak performance.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="product-main-cl">
        <div className="container">
          <h2 className="product-title-main">Related Products</h2>
          <div className="row">
            {showLatest
              ? latestProducts?.map((product) => (
                  <div className="col-md-3 mb-4" key={product._id}>
                    <div className="card" >
                      <div className="zoom-effect">
                        <div className="card-img-container">
                          <img
                            src={product.productImage}
                            className="card-img-top"
                            alt={product.productTitle}
                            onClick={() => viewProduct(product._id)}
                          />
                        </div>
                      </div>
                      <div className="card-body">
                        <h5 className="card-title">{product.productTitle}</h5>
                        {/* <p className="card-text">
                          {product.productDescription}
                        </p> */}
                        <p className="price-product">
                          Price: {product.productPrice}
                        </p>
                        <p className="stock-product">
                          Stock Available: {product.productStockAvailable}
                        </p>
                        {/* <button
                          className="btn btn-success mx-2 view-cart"
                          onClick={() => viewProduct(product._id)}
                        >
                          View Product
                        </button> */}
                        <button
                          className="btn btn-primary add-cart"
                          onClick={() => handleAddToCart(product._id)}
                          disabled={product.productStockAvailable <= 0}
                        >
                          {product.productStockAvailable <= 0
                            ? t("Out of Stock")
                            : t("Add to Cart")}
                        </button>
                      </div>
                    </div>
                  </div>
                ))
              : (query?.length === 0 ? currentProducts : results)?.map(
                  (product, index) => (
                    <div className="col-md-3 mb-4" key={index}>
                      {product.message ? (
                        <div className="card">
                          <div className="card-body">
                            <p>{product.message}</p>
                          </div>
                        </div>
                      ) : (
                        <div className="card" >
                          <div className="zoom-effect">
                            <div className="card-img-container">
                              <img
                                src={product.productImage}
                                className="card-img-top"
                                alt={product.productTitle}
                                onClick={() => viewProduct(product._id)}
                              />
                            </div>
                          </div>
                          <div className="card-body">
                            <h5 className="card-title">
                              {product.productTitle}
                            </h5>
                            {/* <p className="card-text">
                              {product.productDescription}
                            </p> */}
                            <p className="price-product">
                              Price: {product.productPrice}
                            </p>
                            <p className="stock-product">
                              Stock Available: {product.productStockAvailable}
                            </p>
                            {/* <button
                              className="btn btn-success mx-2 view-cart"
                              onClick={() => viewProduct(product._id)}
                            >
                              View Product
                            </button> */}
                            <button
                              className="btn btn-primary add-cart"
                              onClick={() => handleAddToCart(product._id)}
                              disabled={product.productStockAvailable <= 0}
                            >
                              {product.productStockAvailable <= 0
                                ? t("Out of Stock")
                                : t("Add to Cart")}
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  )
                )}
          </div>

          {(query?.length === 0 ? allProducts?.length : results?.length) >
            productsPerPage && (
            <Pagination
              count={totalPages}
              page={page}
              onChange={handleChangePage}
              color="primary"
            />
          )}
        </div>
      </div>
      {isCartPopupOpen && (
        <CartTabPopup
          open={isCartPopupOpen}
          onClose={() => setCartPopupOpen(false)}
        />
      )}
    </>
  );
}
