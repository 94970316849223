import React, { useState } from "react";
import "./CustomerLogin.css";
import Header from "../header/Header";
import { useNavigate } from "react-router-dom";
import {
  CustomerLoginApi,
  ForgotPasswordEmail,
} from "../customerDashboardApi/PostApi";
import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "react-bootstrap/Spinner";
export default function CustomerLogin() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  // ...........onchange function...................
  const onChangeValue = (e) => {
    const { name, value } = e.target;
  
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    console.log(formData, "formData");
  };
  // ..................submit function.................
  const handleSubmit = async (e) => {
    e.preventDefault();
    const { email, password } = formData;
    if (!email && password) {
      toast.error("Please fill all the fields");
    }
    try {
      setIsLoading(true);
      const response = await CustomerLoginApi({ email, password });
      console.log(response, "response");
      if (response.status === 200) {
        toast.success(response.message);
        localStorage.setItem("userEmail", email);
        localStorage.setItem("userToken", response.token);
        setFormData("");
        navigate(`/admin/profile`, { state: { email } });
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.log(error, "error");
      toast.error("something went wrong");
    } finally {
      setIsLoading(false);
    }
  };
  // ...................

  // .....................
  const handleForgotPassword = async (e) => {
    e.preventDefault();
    const { email } = formData;
    if (!email) {
      toast.error("Please enter your email address");
      return;
    }
    try {
      setIsLoading(true);
      const response = await ForgotPasswordEmail(email);
      console.log(response, "frontend response");
      toast.success("OTP sent successfully");
      setFormData({ email: "" });
      navigate(`/admin/forgot?email=${encodeURIComponent(email)}`);
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong, please try again later");
    }finally{
      setIsLoading(false);
    }
  };
  return (
    <div>
      <Header />
      <ToastContainer />
      <div className="login_register">
        <div className="login_inner">
          <form className="form_box">
            <h2>Login your account</h2>
            <div className="fild_box">
              <label>Email Address</label>
              <div className="fild_inner">
                <input
                  type="text"
                  className="custom_form"
                  placeholder="abcd@gmail.com"
                  name="email"
                  required
                  value={formData.email}
                  onChange={onChangeValue}
                />
              </div>
            </div>
            <div className="fild_box no_botm">
              <label>Password</label>
              <div className="fild_inner">
                <input
                  type="password"
                  className="custom_form"
                  placeholder="Password"
                  name="password"
                  required
                  value={formData.password}
                  onChange={onChangeValue}
                />
              </div>
            </div>
            <div className="fild_box forgot_label">
              <p>
                <Link to="/admin/customerRegister">
                  <span>New User?</span>
                </Link>
              </p>
            </div>
            <div className="fild_box forgot_label">
              <p>
                <Link to="#" onClick={handleForgotPassword}>
                  <span>Forgot Password?</span>
                </Link>
              </p>
            </div>
            <div className="fild_box">
              <div className="fild_inner">
                <button className="signup_btn" onClick={handleSubmit}>
                  {isLoading ? (
                    <Spinner animation="border" role="status" />
                  ) : (
                    "Login"
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
