import React, { createContext, useState, useContext, useEffect,useCallback  } from 'react';
import { getCart, getCustomerId } from '../APIComponents/CartsApi';

const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cartItemsCount, setCartItemsCount] = useState(0);

  // const updateCartCount = async () => {
  //   const customerId = getCustomerId();
  //   try {
  //     const response = await getCart(customerId);
  //     const items = response?.data || [];
  //     setCartItemsCount(items.length);
  //   } catch (error) {
  //     console.error('Error fetching cart items:', error);
  //   }
  // };

  const updateCartCount = useCallback(async () => {
    const customerId = getCustomerId();
    try {
      const response = await getCart(customerId);
      const items = response?.data || [];
      setCartItemsCount(items.length);
    } catch (error) {
      console.error('Error fetching cart items:', error);
    }
  }, []);

  useEffect(() => {
    updateCartCount();
  }, [updateCartCount]);

  return (
    <CartContext.Provider value={{ cartItemsCount, updateCartCount }}>
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => useContext(CartContext);
