import axios from "axios";
const url = process.env.REACT_APP_BASE_URL;
// const url='http://localhost:8001'
// const headers = {
//   "ngrok-skip-browser-warning": "true",
// };
const headers = {
  "Content-Type": "application/json",
};
// ..........GET PRODUCT API ................
export async function GetProductAPI() {
  try {
    const response = await axios.get(`${url}/all-products`, {
      headers: headers,
    });
    const data = response.data;
    return data.data;
  } catch (error) {
    console.log("error for fethcing product", error);
    throw error;
  }
}
// ............GET SINGLE PRODUCT API...............
export async function GetSingleProductAPI(id) {
  try {
    const response = await axios.get(`${url}/similar-products/${id}`, {
      headers: headers,
    });
    if (!response) {
      throw new Error("Failed to fetch product");
    }
    console.log("Product fetched successfully:", response);
    return response;
  } catch (error) {
    console.error("Error fetching product:", error);
    throw error;
  }
}
// .........CREATE ORDER API ..............
export async function CreateOrderAPI({ orderData }) {
  try {
    const response = await axios.post(
      `${url}/order-create`,
      { orderData },
      {
        headers: headers,
      }
    );
    console.log("Response from server:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error sending data:", error);
    throw error;
  }
}
// ...............Latest product API.............
export async function LatestProductAPI() {
  try {
    const response = await axios.get(`${url}/products/latest`, {
      headers: headers,
    });
    if (!response) {
      throw new Error("Failed to fetch product");
    }
    console.log("latest product fetched successfully:", response);
    return response;
  } catch (error) {
    console.error("Error fetching product:", error);
    throw error;
  }
}
// ................Email Subscribe API..............
export async function EmailSubscribeApi(data) {
  try {
    const response = await axios.post(
      `${url}/customer/add-subscribe-email`,
      data
    );
    console.log("Response from server:", response);
    return response;
  } catch (error) {
    console.error("Error  to Subscribe Email Api:", error);
    throw error;
  }
}
// ..................Contact Form API................
export async function ContactFormApi(data) {
  try {
    const response = await axios.post(`${url}/contact-form`, data);
    console.log("response from server:", response);
    return response;
  } catch (error) {
    console.log(`Error to save data in contact form`, error);
    throw error;
  }
}
// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
export async function GetPdf() {
  try {
    const response = await axios.get(`${url}/Get-PDF`);
    console.log(response, "response from server");
    return response.data;
  } catch (error) {
    console.log(error, "error");
    return error;
  }
}
