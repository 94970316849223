import React, { useState, useEffect } from "react";
import "./CustomerProfile.css";
import profileIcon from "../../assets/img/profile-ic.png";
import CustomerProfile from "./CustomerProfile";
import { CustomerProfileAPi } from "../customerDashboardApi/PostApi";
import { Link } from "react-router-dom";
import EditIcon from "../../assets/edit-customer-profile.png";
import { useNavigate } from "react-router-dom";
import { FiLogOut } from "react-icons/fi";
export default function Profile() {
  const [fromData, setFormData] = useState(null);
  const [email, setEmail] = useState("");
  const Navigate = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const userToken = localStorage.getItem("userToken");
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem("userToken");
    Navigate("/");
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    const emailFromStorage = localStorage.getItem("userEmail");
    if (emailFromStorage) {
      setEmail(emailFromStorage);
    } else {
      console.error("No email found in local storage");
    }
  }, []);

  useEffect(() => {
    if (!email) return;

    const fetchProfileData = async () => {
      try {
        const response = await CustomerProfileAPi(email);
        console.log(response,"customer")
        if (response.status === 200) {
          setFormData(response.userData);
        } else {
          console.error(response.message, "backend profile response");
        }
      } catch (error) {
        console.error("Error while getting customer profile data:", error);
      }
    };

    fetchProfileData();
  }, [email]);

  return (
    <>
      <CustomerProfile />
      <div className="dashboard-right-main">
      <section className="home">
        <div className="main-header-tag-title">
          <div className="Side-bar-center">Sales Dashboard</div>
          <div className="profile-icon">
            <img
              src={profileIcon}
              className="person-image"
              alt="profile-icon"
              onClick={toggleDropdown}
              style={{ cursor: "pointer" }}
            />
          {isDropdownOpen &&
              userToken && (
                <div className="dropdown-menu">
                  <button className="logout-button" onClick={handleLogout}>
                  <FiLogOut />
                  Logout
                  </button>
                </div>
              )}
          </div>
          
        </div>
      </section>

      <section className="profile-main">
        <div className="container">
          <div className="prfile-box-main">
            <div className="prfile-box">
              <div className="edit-icon">
                <Link to={`/admin/UpdateCustomer?email=${email}`}>
                  <img src={EditIcon} alt="Edit-icon" />
                </Link>
              </div>
              <div className="img-edit-profile"><img
                src={fromData?.Image ? fromData.Image : profileIcon}
                alt="Profile-Person"
                style={{ height: "100px", width: "100%" }}
              /></div>
              <h3 className="name-person">
                <strong>Name:</strong>
                {fromData?.UserName || "N/A"}
              </h3>
              <h3 className="name-person">
                <strong>Email:</strong> {fromData?.Email || "N/A"}
              </h3>
              <h3 className="personn-contact">
                <strong>Contact number:</strong>
                {fromData?.Mobile || "N/A"}
              </h3>
              <p className="person-address">
                <strong>Address:</strong>
                {fromData?.Address || "N/A"}
              </p>
            </div>
          </div>
          
        </div>
      </section>
      </div>
    </>
  );
}
