// import React, { useState, useEffect } from "react";
// import { Link, useNavigate, useLocation } from "react-router-dom";
// import { useTranslation } from "react-i18next";
// import logo from "../../../assets/img/logo.png";
// import CartTabPopup from "../../PopupComponent/CartTabPopup";
// import "./common.css";
// import { CgShoppingBag } from "react-icons/cg";
// import SearchIcon from "@mui/icons-material/Search";
// import { useCart } from "../../PopupComponent/CartContext";
// import { IoCartOutline } from "react-icons/io5";
// import { GetPdf } from "../../APIComponents/ProductAPIs/GetAPI";

// export default function Header() {
//   const { t } = useTranslation();
//   const navigate = useNavigate();
//   const location = useLocation();
//   const { cartItemsCount, updateCartCount } = useCart();
//   const [openCartPopup, setOpenCartPopup] = useState(false);
//   const [query, setQuery] = useState("");
//   const [userToken, setUserToken] = useState(null);
//   const [isMenuOpen, setIsMenuOpen] = useState(false);

//   useEffect(() => {
//     const token = localStorage.getItem("userToken");
//     setUserToken(token);
//   }, []);

//   useEffect(() => {
//     updateCartCount();
//   }, [updateCartCount]);

//   const handleSearch = (e) => {
//     e.preventDefault();
//     if (query.trim() === "") return;
//     navigate(`/all-products?query=${query}`);
//     // setQuery("");
//   };

//   const handleKeyDown = (e) => {
//     if (e.key === "Enter") handleSearch(e);
//   };

//   const toggleMenu = () => {
//     setIsMenuOpen(!isMenuOpen);
//     document.body.classList.toggle("open_menu", !isMenuOpen);
//   };

//   const isActive = (path) => (location.pathname === path ? "active" : "");

//   const HandlePopup = async () => {
//     try {
//       // Assuming GetPdf is an async function
//       const response = await GetPdf();
      
//       // Check if response is valid and contains the filename
//       if (response && response.data && response.data.filename) {
//         // Open the PDF link in a new tab
//         window.open(response.data.filename, '_blank');
//       } else {
//         console.error('PDF link not found in response:', response);
//       }
//     } catch (error) {
//       console.error('Error fetching PDF:', error);
//     }
//   };
  
//   return (
//     <>
//       <div className="top-bar">
//         <p className="topbar-text">
//           Discover Our Latest Deals—{" "}
//           <a onClick={HandlePopup}>Click Here to View the Flyer</a>
//         </p>
//       </div>
//       <div className="main-header">
//         <div className="container">
//           <div className="row align-items-start heade_container">
//             <div className="col-xl-1 col-lg-1 col-md-4 text-start p-0 hamburger-al-nd">
//               <div className="logo-area">
//                 <img
//                   src={logo}
//                   alt="logo.png"
//                   className="img-fluid"
//                   width="150px"
//                   height="70px"
//                   onClick={() => navigate("/")}
//                 />
//               </div>
//               <div className="hamburger-flex">
//                 <div className="hamburger-icon" onClick={toggleMenu}>
//                   <i className="fas fa-bars"></i>
//                   <svg
//                     className={`${!isMenuOpen ? "close_menu" : ""}`}
//                     xmlns="http://www.w3.org/2000/svg"
//                     width="24"
//                     height="24"
//                     viewBox="0 0 24 24"
//                   >
//                     <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z" />
//                   </svg>
//                 </div>
//                 <div
//                   className="cart-icon1 position-relative"
//                   onClick={() => setOpenCartPopup(true)}
//                 >
//                   <IoCartOutline style={{ fontSize: "30px" }} />
//                   {cartItemsCount > 0 && (
//                     <span className="cart-quantity position-absolute">
//                       {cartItemsCount}
//                     </span>
//                   )}
//                 </div>
//               </div>
//             </div>

//             <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-4 text-end center-menu">
//               <div
//                 className={`link-area d-flex justify-content-end nav_link ${
//                   isMenuOpen ? "responsive-menu" : ""
//                 }`}
//               >
//                 <Link to="/" className={isActive("/")} onClick={toggleMenu}>
//                   {t("HOME")}
//                 </Link>
//                 <Link
//                   to="/About-us"
//                   className={isActive("/About-us")}
//                   onClick={toggleMenu}
//                 >
//                   {t("About Us")}
//                 </Link>
//                 <Link
//                   to="/all-products"
//                   className={isActive("/all-products")}
//                   onClick={toggleMenu}
//                 >
//                   {t("Products")}
//                 </Link>
//                 <Link
//                   to="/Services"
//                   className={isActive("/Services")}
//                   onClick={toggleMenu}
//                 >
//                   {t("Services")}
//                 </Link>
//                 <Link
//                   to="/Why-us"
//                   className={isActive("/Why-us")}
//                   onClick={toggleMenu}
//                 >
//                   {t("Why Us")}
//                 </Link>
//                 <Link
//                   to="/Contact"
//                   className={isActive("/Contact")}
//                   onClick={toggleMenu}
//                 >
//                   {t("Contact")}
//                 </Link>
//               </div>
//             </div>

//             <div className="col-xxl-3 col-xl-2 col-lg-2 col-md-2 text-end search-box">
//               <div className="search-main-hd">
//                 <input
//                   type="search"
//                   placeholder="Search"
//                   aria-label="Search"
//                   value={query}
//                   onChange={(e) => setQuery(e.target.value)}
//                   onKeyDown={handleKeyDown}
//                   className="form-control search-bar-hd"
//                 />
//                 <div className="search-ic-hd">
//                   {!query && <SearchIcon onClick={handleSearch} />}
//                 </div>
//               </div>
//             </div>

//             <div className="col-xxl-2 col-xl-3 col-lg-3 col-md-2 d-flex mr-3 gap-3 text-end p-0 cart_container">
//               <div className="custom-buttons">
//                 {!userToken ? (
//                   <button
//                     className="btn btn-primary"
//                     onClick={() => navigate("/admin/customerLogin")}
//                   >
//                     {t("NEW CUSTOMER")}
//                   </button>
//                 ) : (
//                   <button
//                     className="btn btn-primary"
//                     onClick={() => navigate("/admin/profile")}
//                   >
//                     {t("EXISTING CUSTOMER")}
//                   </button>
//                 )}
//               </div>
//               <div
//                 className="cart-icon position-relative"
//                 onClick={() => setOpenCartPopup(true)}
//               >
//                 <IoCartOutline style={{ fontSize: "30px" }} />
//                 {cartItemsCount > 0 && (
//                   <span className="cart-quantity position-absolute">
//                     {cartItemsCount}
//                   </span>
//                 )}
//               </div>

//               {/* <div
//                 className="cart-icon position-relative"
//                 onClick={() => setOpenCartPopup(true)}
//               >
//                 <IoCartOutline style={{ fontSize: "30px" }} />
//                 <span className="cart-quantity position-absolute">
//                   {cartItemsCount}
//                 </span>
//               </div> */}
//             </div>
//           </div>
//         </div>
//       </div>
//       {openCartPopup && (
//         <CartTabPopup
//           open={openCartPopup}
//           onClose={() => setOpenCartPopup(false)}
//           onCartUpdate={updateCartCount}
//         />
//       )}
//     </>
//   );
// }



import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import logo from "../../../assets/img/logo.png";
import CartTabPopup from "../../PopupComponent/CartTabPopup";
import "./common.css";
import { CgShoppingBag } from "react-icons/cg";
import SearchIcon from "@mui/icons-material/Search";
import { useCart } from "../../PopupComponent/CartContext";
import { IoCartOutline } from "react-icons/io5";
import { GetPdf } from "../../APIComponents/ProductAPIs/GetAPI";
import { FaTimes } from "react-icons/fa";

export default function Header() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { cartItemsCount, updateCartCount } = useCart();
  const [openCartPopup, setOpenCartPopup] = useState(false);
  const [query, setQuery] = useState("");
  const [userToken, setUserToken] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [pdfLink, setPdfLink] = useState("");
  const [showFlyer, setShowFlyer] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem("userToken");
    setUserToken(token);
  }, []);

  useEffect(() => {
    updateCartCount();
  }, [updateCartCount]);

  useEffect(() => {
    const fetchPdfLink = async () => {
      try {
        const response = await GetPdf();
        if (response && response.data && response.data.filename) {
          setPdfLink(response.data.filename);
        } else {
          console.error('PDF link not found in response:', response);
        }
      } catch (error) {
        console.error('Error fetching PDF:', error);
      }
    };

    fetchPdfLink();
  }, []);

  const handleSearch = (e) => {
    e.preventDefault();
    if (query.trim() === "") return;
    navigate(`/all-products?query=${query}`);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") handleSearch(e);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    document.body.classList.toggle("open_menu", !isMenuOpen);
  };


  const handleCloseFlyer = () => {
    setShowFlyer(false); 
  };
  const isActive = (path) => (location.pathname === path ? "active" : "");

  return (
    <>
     {showFlyer && (
        <div className="top-bar">
          <p className="topbar-text">
            Discover Our Latest Deals—{" "}
            {pdfLink ? (
              <a href={pdfLink} target="_blank" rel="noopener noreferrer">
                Click Here to View the Flyer
              </a>
            ) : (
              "Loading..."
            )}
            <FaTimes
              onClick={handleCloseFlyer}
              style={{ marginLeft: '10px', cursor: 'pointer' }} // Style the cross icon
            />
          </p>
        </div>
      )}
      <div className="main-header">
        <div className="container">
          <div className="row align-items-start heade_container">
            <div className="col-xl-1 col-lg-1 col-md-4 text-start p-0 hamburger-al-nd">
              <div className="logo-area">
                <img
                  src={logo}
                  alt="logo.png"
                  className="img-fluid"
                  width="150px"
                  height="70px"
                  onClick={() => navigate("/")}
                />
              </div>
              <div className="hamburger-flex">
                <div className="hamburger-icon" onClick={toggleMenu}>
                  <i className="fas fa-bars"></i>
                  <svg
                    className={`${!isMenuOpen ? "close_menu" : ""}`}
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z" />
                  </svg>
                </div>
                <div
                  className="cart-icon1 position-relative"
                  onClick={() => setOpenCartPopup(true)}
                >
                  <IoCartOutline style={{ fontSize: "30px" }} />
                  {cartItemsCount > 0 && (
                    <span className="cart-quantity position-absolute">
                      {cartItemsCount}
                    </span>
                  )}
                </div>
              </div>
            </div>

            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-4 text-end center-menu">
              <div
                className={`link-area d-flex justify-content-end nav_link ${
                  isMenuOpen ? "responsive-menu" : ""
                }`}
              >
                <Link to="/" className={isActive("/")} onClick={toggleMenu}>
                  {t("HOME")}
                </Link>
                <Link
                  to="/About-us"
                  className={isActive("/About-us")}
                  onClick={toggleMenu}
                >
                  {t("About Us")}
                </Link>
                <Link
                  to="/all-products"
                  className={isActive("/all-products")}
                  onClick={toggleMenu}
                >
                  {t("Products")}
                </Link>
                <Link
                  to="/Services"
                  className={isActive("/Services")}
                  onClick={toggleMenu}
                >
                  {t("Services")}
                </Link>
                <Link
                  to="/Why-us"
                  className={isActive("/Why-us")}
                  onClick={toggleMenu}
                >
                  {t("Why Us")}
                </Link>
                <Link
                  to="/Contact"
                  className={isActive("/Contact")}
                  onClick={toggleMenu}
                >
                  {t("Contact")}
                </Link>
              </div>
            </div>

            <div className="col-xxl-3 col-xl-2 col-lg-2 col-md-2 text-end search-box">
              <div className="search-main-hd">
                <input
                  type="search"
                  placeholder="Search"
                  aria-label="Search"
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                  onKeyDown={handleKeyDown}
                  className="form-control search-bar-hd"
                />
                <div className="search-ic-hd">
                  {!query && <SearchIcon onClick={handleSearch} />}
                </div>
              </div>
            </div>

            <div className="col-xxl-2 col-xl-3 col-lg-3 col-md-2 d-flex mr-3 gap-3 text-end p-0 cart_container">
              <div className="custom-buttons">
                {!userToken ? (
                  <button
                    className="btn btn-primary"
                    onClick={() => navigate("/admin/customerLogin")}
                  >
                    {t("NEW CUSTOMER")}
                  </button>
                ) : (
                  <button
                    className="btn btn-primary"
                    onClick={() => navigate("/admin/profile")}
                  >
                    {t("EXISTING CUSTOMER")}
                  </button>
                )}
              </div>
              <div
                className="cart-icon position-relative"
                onClick={() => setOpenCartPopup(true)}
              >
                <IoCartOutline style={{ fontSize: "30px" }} />
                {cartItemsCount > 0 && (
                  <span className="cart-quantity position-absolute">
                    {cartItemsCount}
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {openCartPopup && (
        <CartTabPopup
          open={openCartPopup}
          onClose={() => setOpenCartPopup(false)}
          onCartUpdate={updateCartCount}
        />
      )}
    </>
  );
}
