import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { GetSingleProductAPI } from "../../../CustomerDashboard/APIComponents/ProductAPIs/GetAPI";
import "./common.css";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import { MDBSpinner } from "mdb-react-ui-kit";
import { Toaster, toast } from "react-hot-toast";
import { BsCartCheck } from "react-icons/bs";
import { useCart } from "../../PopupComponent/CartContext";
import { AddToCart, getCustomerId } from "../../APIComponents/CartsApi";
import CartTabPopup from "../../PopupComponent/CartTabPopup";
export default function SingleProductPage() {
  const { updateCartCount } = useCart();
  const navigate = useNavigate();
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [similarProducts, setSimilarProducts] = useState([]);
  const [isCartPopupOpen, setCartPopupOpen] = useState(false);
  const [cartItem, setCartItem] = useState(null);
  // FETCH SINGLE PRODUCT
  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await GetSingleProductAPI(id);
        if (response.status === 200) {
          setProduct(response.data.product);
          setSimilarProducts(response.data.similarProducts);
          console.log(
            response.data.product.productAdditionalImages,
            "additionalimage"
          );
        } else {
          console.error("Error fetching product:", response.error);
        }
      } catch (error) {
        console.error("Error fetching product:", error);
      }
    };

    fetchProduct();
  }, [id]);

  // Function to handle quantity change
  const handleQuantityChange = (e) => {
    const newQuantity = parseInt(e.target.value, 10);
    setQuantity(newQuantity);
  };

  const handleAddToCart = async (productId) => {
    try {
      const customerId = getCustomerId();
      console.log("Customer ID used:", customerId);

      const response = await AddToCart(customerId, productId, quantity);
      console.log("API Response:", response);

      if (response && response.status === 200) {
        toast.success(response.message);
        setQuantity(1);
        updateCartCount();
        setCartItem(productId);
        setCartPopupOpen(true);
      }
    } catch (error) {
      console.error("Error adding to cart:", error);
      toast.error("Error adding product to cart");
    }
  };
  // const handleAddToCart = async (productId) => {
  //   try {
  //     const customerId = getCustomerId();
  //     console.log("Customer ID used:", customerId);

  //     const response = await AddToCart(customerId, productId, 1);
  //     console.log("API Response:", response);

  //     if (response && response.status === 200) {
  //       toast.success(response.message);
  //       setQuantity(1);
  //     } else {
  //       toast.error("Failed to add product to cart");
  //     }
  //   } catch (error) {
  //     console.error("Error adding to cart:", error);
  //     toast.error("Error adding product to cart");
  //   }
  // };
  const buyProduct = (productId) => {
    console.log("Navigating to checkout with productId:", productId);
    const customerId = getCustomerId();
    navigate("/Checkout", {
      state: {
        productId,
        quantity
      },
    });
  };

  const handleImageClick = (image) => {
    setProduct((prevProduct) => ({
      ...prevProduct,
      productImage: image,
    }));
  };

  if (!product) {
    return (
      <MDBSpinner role="status" color="primary">
        <span className="visually-hidden">Loading...</span>
      </MDBSpinner>
    );
  }

  // NAVIGATE TO SIMILAR PRODUCT
  const viewSimilarProduct = (productId) => {
    navigate(`/viewSingleProduct/${productId}`);
  };

  return (
    <>
      <Toaster position="top-right" reverseOrder={false} />
      <div className="single-product-main">
        <div className="container mt-5">
          <div className="row single-pro-lc">
            <div className="col-lg-6 py-2 px-2">
              <div className="carousel slide" data-ride="carousel">
                <div className="carousel-inner product-image">
                  <div className="carousel-item active">
                    <img
                      src={product.productImage}
                      className="d-block w-100"
                      alt="Product"
                    />
                  </div>
                </div>

                {/* Additional images thumbnails */}
                <div className="container">
                  <div className="row gallery">
                    {product.productAdditionalImages.map((image, index) => (
                      <div className="col product-gallery" key={index}>
                        <img
                          src={image}
                          style={{ width: "90%", cursor: "pointer" }}
                          onClick={() => handleImageClick(image)}
                        />
                      </div>
                    ))}
                  </div>
                </div>

                {/* Carousel navigation controls */}
                {/* <a
                className="carousel-control-prev"
                role="button"
                data-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span className="sr-only">Previous</span>
              </a>
              <a
                className="carousel-control-next"
                href="#carouselExampleControls"
                role="button"
                data-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span className="sr-only">Next</span>
              </a> */}
              </div>
            </div>

            <div className="col-lg-6 border right-side-product-xc">
              <h2 className="single-pro-cl">{product.productTitle}</h2>
              <h3>{product.productPrice}</h3>
              <p className="single-pro-detail">{product.productDescription}</p>
              <div
                className="d-flex align-items-center mb-3 select-option-single"
                style={{ marginLeft: "18%" }}
              ></div>

              <select
                className="form-select form-select-sm mx-2 selct-fom-cl"
                style={{ width: "10%" }}
                value={quantity}
                onChange={handleQuantityChange}
              >
                {[...Array(10).keys()].map((num) => (
                  <option key={num + 1} value={num + 1}>
                    {num + 1}
                  </option>
                ))}
              </select>

              <div className="pro-cart-btn">
                <button
                  className="btn btn-primary mx-2 addcart-single-pro"
                  onClick={() => handleAddToCart(product._id)}
                  disabled={product.productStockAvailable <= 0}
                >
                  {product.productStockAvailable <= 0
                    ? "Out of Stock"
                    : "Add to Cart"}
                </button>
                <button
                  className="btn btn-success singlpro-buy-now"
                  onClick={() => buyProduct(product._id)}
                >
                  Buy Now
                </button>
              </div>

              <div className="col-sm delivery-cl">
                <h3>Delivery</h3>
                <p>
                  Free Standard Shipping on Orders above 300$ Before Tax, plus
                  Free Returns
                </p>
              </div>

              <div className="col-sm shar-pro">
                <h3>Share</h3>
                <FacebookIcon />
                <TwitterIcon />
                <InstagramIcon />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="similar-product-main">
        <div className="container">
          <div className="row mt-5">
            <div className="col">
              <h4 className="product-similar">Similar Products</h4>
            </div>
          </div>
          <div className="row mt-3">
            {similarProducts?.map((similarProduct) => (
              <div className="col-lg-3 mb-4" key={similarProduct._id}>
                <div className="card">
                  <img
                    src={similarProduct.productImage}
                    className="card-img-top"
                    alt="Similar Product"
                  />
                  <div className="card-body">
                    <h5 className="card-title">
                      {similarProduct.productTitle}
                    </h5>
                    <p className="card-text">{similarProduct.productPrice}</p>
                    <button
                      className="btn btn-primary view-product"
                      onClick={() => viewSimilarProduct(similarProduct._id)}
                    >
                      <BsCartCheck />
                      View Product
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {isCartPopupOpen && (
        <CartTabPopup
          open={isCartPopupOpen}
          onClose={() => setCartPopupOpen(false)}
        />
      )}
    </>
  );
}
