// import React, { useState, useEffect } from "react";
// import "./Checkout.css";
// import { getCustomerId, getCart } from "../../../../APIComponents/CartsApi";

// export default function CheckoutData({ onTotalAmountChange }) {
//   const [cartItems, setCartItems] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [isSummaryVisible, setIsSummaryVisible] = useState(true);

//   // Function to calculate subtotal
//   const calculateSubtotal = () => {
//     return cartItems.reduce((acc, item) => {
//       const priceStr = item?.productDetails?.productPrice || "$0.00";
//       const price =
//         parseFloat(priceStr.replace("$", "").replace(/[^0-9.-]+/g, "")) || 0;
//       const quantity = parseInt(item?.Quantity, 10) || 1;
//       return acc + price * quantity;
//     }, 0);
//   };

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const customerId = getCustomerId();
//         const response = await getCart(customerId);
//         setCartItems(response.data || []);
//         setLoading(false);
//       } catch (error) {
//         console.error("Error fetching cart data:", error);
//         setError(error);
//         setLoading(false);
//       }
//     };
//     fetchData();
//   }, []);

//   useEffect(() => {
//     const subtotal = calculateSubtotal();
//     const tax = 0.0;
//     const total = subtotal + tax;
//     onTotalAmountChange(total);
//   }, [cartItems, onTotalAmountChange]);

//   if (loading) return <p>Loading...</p>;
//   if (error) return <p>Error: {error.message}</p>;

//   const handleToggleSummary = () => {
//     setIsSummaryVisible((prevState) => !prevState);
//   };

//   const subtotal = calculateSubtotal();
//   const tax = 0.0;
//   const total = subtotal + tax;

//   return (
//     <>
//       <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 right-checkout"></div>
//       <div className="show-order-summary">
//         <div className="show-hide-flex">
//           <div className="show-title">
//             <button onClick={handleToggleSummary}>
//               {isSummaryVisible ? "Hide Order Summary" : "Show Order Summary"}
//             </button>
//           </div>
//           <div className="price-mb">
//             <span className="check-price">${total.toFixed(2)}</span>
//           </div>
//         </div>
//       </div>
//       {isSummaryVisible && (
//         <div className="form-two">
//           {cartItems.length === 0 ? (
//             <p>No items in the cart.</p>
//           ) : (
//             cartItems.map((item, index) => (
//               <div key={index} className="right-check">
//                 <div className="flex-main-lc">
//                   <div className="img-product">
//                     <img
//                       src={
//                         item?.productDetails?.productImage ||
//                         "default-image-url"
//                       }
//                       alt={item?.productDetails?.productTitle || "Product"}
//                       style={{ height: "150px", width: "60%" }}
//                     />
//                   </div>
//                   <div className="quantity">
//                     <p>Quantity: {item?.Quantity || 1}</p>
//                   </div>
//                 </div>
//                 <div className="prod-details">
//                   <div className="product-title">
//                     <h2>{item?.productDetails?.productTitle || "No Title"}</h2>
//                   </div>
//                   <div className="product-price">
//                     <h2>{item?.productDetails?.productPrice}</h2>
//                   </div>
//                 </div>
//               </div>
//             ))
//           )}

//           <div className="coupan-code">
//             <div className="coupan-code">
//               <input
//                 type="text"
//                 className="form-control"
//                 id="address"
//                 placeholder="Discount code"
//                 value=""
//               />
//             </div>
//             <div className="apply-btn">
//               <button>Apply</button>
//             </div>
//           </div>
//           <div className="footer-data">
//             <div className="container">
//               <div className="row">
//                 <div className="col">Subtotal</div>
//                 <div className="col">${subtotal.toFixed(2)}</div>
//               </div>
//             </div>
//             <div className="container">
//               <div className="row">
//                 <div className="col">Shipping</div>
//                 <div className="col">Enter shipping address</div>
//               </div>
//             </div>
//             <div className="container">
//               <div className="row">
//                 <div className="col">Estimated taxes</div>
//                 <div className="col">${tax.toFixed(2)}</div>
//               </div>
//             </div>
//             <div className="container">
//               <div className="row">
//                 <div className="col total_price">Total</div>
//                 <div className="col price_amount">${total.toFixed(2)}</div>
//               </div>
//             </div>
//           </div>
//         </div>
//       )}
//     </>
//   );
// }

import React, { useState, useEffect } from "react";
import "./Checkout.css";
import { getCustomerId, getCart } from "../../../../APIComponents/CartsApi";
import { GetSingleProductAPI } from "../../../../APIComponents/ProductAPIs/GetAPI";
export default function CheckoutData({
  productId,
  quantity,
  onTotalAmountChange,
}) {
  console.log(quantity, "quantity of checkout data");
  console.log(productId, "productId of checkout data");
  const [cartItems, setCartItems] = useState([]);
  const [productDetails, setProductDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isSummaryVisible, setIsSummaryVisible] = useState(true);
  useEffect(() => {
    console.log("Product ID:", productId);
    console.log("Quantity:", quantity);
  }, [productId, quantity]);
  const calculateSubtotal = () => {
    if (productDetails) {
      const priceStr = productDetails.productPrice || "$0.00";
      const price = parseFloat(priceStr.replace("$", "").replace(/[^0-9.-]+/g, "")) || 0;
      return price * (quantity || 1); 
    } else {
      return cartItems.reduce((acc, item) => {
        const priceStr = item?.productDetails?.productPrice || "$0.00";
        const price = parseFloat(priceStr.replace("$", "").replace(/[^0-9.-]+/g, "")) || 0;
        const quantity = parseInt(item?.Quantity, 10) || 1;
        return acc + price * quantity;
      }, 0);
    }
  };
  // const calculateSubtotal = () => {
  //   return cartItems.reduce((acc, item) => {
  //     const priceStr = item?.productDetails?.productPrice || "$0.00";
  //     const price =
  //       parseFloat(priceStr.replace("$", "").replace(/[^0-9.-]+/g, "")) || 0;
  //     const quantity = parseInt(item?.Quantity, 10) || 1;
  //     return acc + price * quantity;
  //   }, 0);
  // };
  useEffect(() => {
    const fetchCartData = async () => {
      try {
        const customerId = getCustomerId();
        const response = await getCart(customerId);
        setCartItems(response.data || []);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching cart data:", error);
        setError(error);
        setLoading(false);
      }
    };
    fetchCartData();
  }, []);
  useEffect(() => {
    const fetchProductDetails = async () => {
      if (productId) {
        try {
          const response = await GetSingleProductAPI(productId);
          setProductDetails(response.data.product);
        } catch (error) {
          console.error("Error fetching product details:", error);
          setError(error);
        }
      }
    };
    fetchProductDetails();
  }, [productId]);
  useEffect(() => {
    if (cartItems.length > 0 || productDetails) {
      const subtotal = calculateSubtotal();
      const tax = 0.0;
      const total = subtotal + tax;
      onTotalAmountChange(total);
    }
  }, [cartItems,quantity, productDetails, onTotalAmountChange]);
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;
  const handleToggleSummary = () => {
    setIsSummaryVisible((prevState) => !prevState);
  };
  const subtotal = calculateSubtotal();
  const tax = 0.0;
  const total = subtotal + tax;
  return (
    <>
      <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 right-checkout"></div>
      <div className="show-order-summary">
        <div className="show-hide-flex">
          <div className="show-title">
            <button onClick={handleToggleSummary}>
              {isSummaryVisible ? "Hide Order Summary" : "Show Order Summary"}
            </button>
          </div>
          <div className="price-mb">
            <span className="check-price">${total.toFixed(2)}</span>
          </div>
        </div>
      </div>
      {isSummaryVisible && (
        <div className="form-two">
          {productDetails ? (
            <div className="right-check">
              <div className="flex-main-lc">
                <div className="img-product">
                  <img
                    src={productDetails.productImage || "default-image-url"}
                    alt={productDetails.productTitle || "Product"}
                    style={{ height: "150px", width: "60%" }}
                  />
                </div>
                <div className="quantity">
                  <p>Quantity: {quantity}</p>
                </div>
              </div>
              <div className="prod-details">
                <div className="product-title">
                  <h2>{productDetails.productTitle || "No Title"}</h2>
                </div>
                <div className="product-price">
                  <h2>{productDetails.productPrice}</h2>
                </div>
              </div>
            </div>
          ) : cartItems.length === 0 ? (
            <p>No items in the cart.</p>
          ) : (
            cartItems.map((item, index) => (
              <div key={index} className="right-check">
                <div className="flex-main-lc">
                  <div className="img-product">
                    <img
                      src={
                        item?.productDetails?.productImage ||
                        "default-image-url"
                      }
                      alt={item?.productDetails?.productTitle || "Product"}
                      style={{ height: "150px", width: "60%" }}
                    />
                  </div>
                  <div className="quantity">
                    <p>Quantity: {item?.Quantity || 1}</p>
                  </div>
                </div>
                <div className="prod-details">
                  <div className="product-title">
                    <h2>{item?.productDetails?.productTitle || "No Title"}</h2>
                  </div>
                  <div className="product-price">
                    <h2>{item?.productDetails?.productPrice}</h2>
                  </div>
                </div>
              </div>
            ))
          )}
           <div className="checkoutcoupan-top">  
          <div className="checkout-price-btm">     
                 <div className="coupan-code">
            <input
              type="text"
              className="form-control"
              id="address"
              placeholder="Discount code"
              value=""
            />
            <div className="apply-btn">
              <button>Apply</button>{" "}
            </div>
          </div>
          <div className="footer-data">
            <div className="container">
              <div className="row">
                <div className="col">Subtotal</div>
                <div className="col">${subtotal.toFixed(2)}</div>
              </div>
            </div>
            <div className="container">
              <div className="row">
                <div className="col">Shipping</div>
                <div className="col">Enter shipping address</div>
              </div>
            </div>
            <div className="container">
              <div className="row">
                <div className="col">Estimated taxes</div>
                <div className="col">${tax.toFixed(2)}</div>
              </div>
            </div>
            <div className="container">
              <div className="row">
                <div className="col total_price">Total</div>
                <div className="col price_amount">${total.toFixed(2)}</div>
              </div>
            </div>
          </div>
        </div>
        </div>
         </div>

      )}
    </>
  );
}
