import React, { useState, useEffect } from "react";
import Header from "../header/Header";
import { ForgotPasswords } from "../customerDashboardApi/PostApi";
import "./ForgotPassword.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
export default function ForgotPassword() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [formState, setFormState] = useState({
    passwordVisible: false,
    confirmPasswordVisible: false,
    email: "",
    otp: ["", "", "", ""],
    newPassword: "",
    confirmPassword: "",
  });
  const togglePasswordVisibility = () => {
    setFormState((prevState) => ({
      ...prevState,
      passwordVisible: !prevState.passwordVisible,
    }));
  };
  const toggleConfirmPasswordVisibility = () => {
    setFormState((prevState) => ({
      ...prevState,
      confirmPasswordVisible: !prevState.confirmPasswordVisible,
    }));
  };
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const emailParam = queryParams.get("email");
    if (emailParam) {
      setFormState((prevState) => ({
        ...prevState,
        email: emailParam,
      }));
    }
  }, []);
  const validateForm = () => {
    const { otp, newPassword, confirmPassword, email } = formState;
    if (!email || !/\S+@\S+\.\S+/.test(email)) {
      toast.error("Please enter a valid email address");
      return false;
    }
    const otpString = otp.join("");
    if (otpString.length !== 4 || !/^\d{4}$/.test(otpString)) {
      toast.error("Please enter a valid 4-digit OTP");
      return false;
    }
    if (!newPassword || !confirmPassword) {
      toast.error("Both password fields are required");
      return false;
    }
    if (newPassword !== confirmPassword) {
      toast.error("Passwords do not match");
      return false;
    }
    if (newPassword.length < 6) {
      toast.error("New password must be at least 6 characters long");
      return false;
    }
    return true;
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validateForm()) return;
    try {
      setIsLoading(true);
      const resetCode = formState.otp.join("");
      await ForgotPasswords(
        formState.email,
        resetCode,
        formState.newPassword,
        formState.confirmPassword
      );
      toast.success("Password reset successfully");
      setIsLoading(false);
      navigate("/admin/customerLogin");
      setFormState({
        passwordVisible: false,
        confirmPasswordVisible: false,
        email: "",
        otp: ["", "", "", ""],
        newPassword: "",
        confirmPassword: "",
      });
    } catch (error) {
      toast.error("Failed to reset password");
      setIsLoading(false);
      console.log("error", error);
    }
  };
  const handleChange = (event) => {
    const { name, value, dataset } = event.target;
    if (name === "otp") {
      const index = parseInt(dataset.index, 10);
      setFormState((prevState) => {
        const otp = [...prevState.otp];
        otp[index] = value;
        if (value && index < prevState.otp.length - 1) {
          document.querySelector(`input[data-index='${index + 1}']`).focus();
        }
        if (value === "" && index > 0) {
          document.querySelector(`input[data-index='${index - 1}']`).focus();
        }
        return { ...prevState, otp };
      });
    } else {
      setFormState((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };
  return (
    <>
      <Header />
      <ToastContainer />
      <div className="login_register">
        <div className="login_inner">
          <form className="form_box" onSubmit={handleSubmit}>
            <h2 className="change-pas">Forgot Password</h2>
            <div className="field_box reset-pass">
              <label>Reset code</label>
              <div className="otp-box">
                {Array.isArray(formState.otp) &&
                  formState.otp.map((value, index) => (
                    <input
                      key={index}
                      type="text"
                      className="custom_form"
                      maxLength="1"
                      name="otp"
                      data-index={index}
                      value={value}
                      onChange={handleChange}
                    />
                  ))}
              </div>
            </div>
            <div className="field_box">
              <label>New Password</label>
              <div className="field_inner">
                <input
                  type={formState.passwordVisible ? "text" : "password"}
                  className="custom_form"
                  name="newPassword"
                  placeholder="#1234567"
                  required
                  value={formState.newPassword}
                  onChange={handleChange}
                />
                <span
                  className="toggle-password"
                  onClick={togglePasswordVisibility}
                >
                  <i
                    className={`fas fa-eye${
                      formState.passwordVisible ? "-slash" : ""
                    }`}
                  />
                </span>
              </div>
            </div>
            <div className="field_box no_botm">
              <label>Confirm Password</label>
              <div className="field_inner">
                <input
                  type={formState.confirmPasswordVisible ? "text" : "password"}
                  className="custom_form"
                  name="confirmPassword"
                  placeholder=""
                  required
                  value={formState.confirmPassword}
                  onChange={handleChange}
                />
                <span
                  className="toggle-password"
                  onClick={toggleConfirmPasswordVisibility}
                >
                  <i
                    className={`fas fa-eye${
                      formState.confirmPasswordVisible ? "-slash" : ""
                    }`}
                  />
                </span>
              </div>
            </div>
            <div className="fild_box">
              <div className="fild_inner">
                <button className="signup_btn">
                  {isLoading ? (
                    <Spinner animation="border" role="status" />
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
