import React, { useState, useEffect } from "react";
import "./ChangePassword.css";
import Header from "../header/Header";
import { Toaster, toast } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { confirmPassword } from "../customerDashboardApi/PostApi";
import Spinner from "react-bootstrap/Spinner";
export default function SetCustomerPassword() {
  const Navigate=useNavigate()
  const location = useLocation();
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    password: "",
    confirmPasswords: "",
  });
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const emailFromParams = queryParams.get("email");
    setEmail(emailFromParams);
  }, [location.search]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const { password, confirmPasswords } = formData;

    if (!password || !confirmPasswords) {
      return toast.error("Please enter both password fields");
    }
    if (password !== confirmPasswords) {
      return toast.error("Password and confirm password should be the same");
    }
    try {
      setIsLoading(true);
      const response = await confirmPassword({ email,password });
      if (response.status === 200) {
        toast.success(response.message);
        setFormData("")
        Navigate('/admin/customerLogin')
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };
  const togglePasswordVisibility = (inputId) => {
    const inputField = document.getElementById(inputId);
    const toggleIcon =
      inputField.parentElement.querySelector(".toggle-password i");

    if (inputField.type === "password") {
      inputField.type = "text";
      toggleIcon.classList.remove("fa-eye");
      toggleIcon.classList.add("fa-eye-slash");
    } else {
      inputField.type = "password";
      toggleIcon.classList.remove("fa-eye-slash");
      toggleIcon.classList.add("fa-eye");
    }
  };
  return (
    <div>
      <Header />
      <Toaster position="top-right" reverseOrder={false} />
      <div className="login_register">
        <div className="login_inner">
          <form className="form_box" onSubmit={handleSubmit}>
            <h2 className="change-pas">Set Password</h2>
            <div className="field_box">
              <label>Enter Your New Password</label>
              <div className="field_inner">
                <input
                  type="password"
                  className="custom_form"
                  id="passwordInput"
                  placeholder="#1234567"
                  required
                  name="password"
                  onChange={handleChange}
                />
                <span
                  className="toggle-password"
                  onClick={() => togglePasswordVisibility("passwordInput")}
                >
                  <i className="fas fa-eye" />
                </span>
              </div>
            </div>
            <div className="field_box no_botm">
              <label>Confirm Password</label>
              <div className="field_inner">
                <input
                  type="password"
                  className="custom_form"
                  id="confirmPasswordInput"
                  placeholder=""
                  required
                  name="confirmPasswords"
                  onChange={handleChange}
                />
                <span
                  className="toggle-password"
                  onClick={() =>
                    togglePasswordVisibility("confirmPasswordInput")
                  }
                >
                  <i className="fas fa-eye" />
                </span>
              </div>
            </div>
            <div className="fild_box forgot_label">
              {/* <p><span>Forgot Password?</span></p> */}
            </div>
            <div className="fild_box">
              <div className="fild_inner">
                <button className="signup_btn" type="submit">
                  {isLoading ? (
                    <Spinner animation="border" role="status" />
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
