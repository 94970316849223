import React, { useState } from "react";
import "./customerRegister.css";
import Header from "../header/Header";
import { CustomerRegister } from "../customerDashboardApi/PostApi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import { Link } from "react-router-dom";
export default function CustomerRegistration() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [FormData, setFormData] = useState({
    userName: "",
    mobile: "",
    email: "",
  });

  // Function to handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Function to handle form submission
  const submitRegister = async (e) => {
    e.preventDefault();

    const { userName, mobile, email } = FormData;
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!userName || !mobile || !email) {
      return toast.error("Please fill out all fields.");
    }

    if (!emailPattern.test(email)) {
      return toast.error("Please enter a valid email address!");
    }

    try {
      setIsLoading(true)
      const response = await CustomerRegister(FormData);
      if (response.status === 200) {
        setIsLoading(false);
        setFormData({
          userName: "",
          mobile: "",
          email: "",
        });
        toast.success(response.message);
        setTimeout(() => {
          navigate(`/admin/customerOtp?email=${encodeURIComponent(email)}`);
        }, 3000);
      } else {
        setIsLoading(false);
        toast.error(response.message);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(`Something went wrong: ${error}`);
      toast.error(error.message);
    }
  };

  return (
    <div>
      <Header />
      <ToastContainer />
      <div className="login_register">
        <div className="login_inner">
          <form className="form_box" onSubmit={submitRegister}>
            <h2 className="register-title">Register your Account</h2>
            <div className="field_box">
              <label>Name</label>
              <div className="field_inner">
                <input
                  type="text"
                  className="custom_form"
                  placeholder="Please Enter your name"
                  required
                  name="userName"
                  value={FormData.userName}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="field_box">
              <label>Mobile Number</label>
              <div className="field_inner">
                <input
                  type="text"
                  className="custom_form"
                  placeholder="Please Enter Your Mobile Number"
                  required
                  name="mobile"
                  value={FormData.mobile}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="field_box">
              <label>Email Address</label>
              <div className="field_inner">
                <input
                  type="email"
                  className="custom_form"
                  placeholder="abcd@gmail.com"
                  required
                  name="email"
                  value={FormData.email}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="fild_box forgot_label"></div>
            <div className="fild_box">
              <div className="fild_inner">
                <button className="signup_btn" type="submit">
                  {isLoading ? (
                    <Spinner animation="border" role="status" />
                  ) : (
                    "Submit"
                  )}
                </button>

                <div className="fild_box forgot_label">
                  <p>
                    <Link to="/admin/customerLogin">
                      <span>Already Have An Account ?</span>
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
