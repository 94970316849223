// import React, { useState } from "react";
// import CustomerProfile from "./CustomerProfile";
// import profileIcon from "../../assets/img/profile-ic.png";
// import "./CustomerProfile.css"

// export default function OrderList() {
//   const [activeTab, setActiveTab] = useState("London");

//   const openCity = (event, cityName) => {
//     setActiveTab(cityName);
//   };

//   return (
//     <div>
//       <CustomerProfile />
//       <section className="home">
//         <div className="main-header-tag-title">
//           <div className="Side-bar-center">Sales Dashboard</div>
//           <div className="profile-icon">
//             <a href="#">
//               <img
//                 src={profileIcon}
//                 className="person-image"
//                 alt="profile-icon"
//               />
//             </a>
//           </div>
//         </div>
//       </section>
//       <section className="order-list-main">
//         <div className="container1">
//           <div className="tab">
//             <button
//               className={`tablinks ${activeTab === "London" ? "active" : ""}`}
//               onClick={(e) => openCity(e, "London")}
//             >
//               All
//             </button>
//             <button
//               className={`tablinks ${activeTab === "Paris" ? "active" : ""}`}
//               onClick={(e) => openCity(e, "Paris")}
//             >
//               In Progress
//             </button>
//             <button
//               className={`tablinks ${activeTab === "Tokyo" ? "active" : ""}`}
//               onClick={(e) => openCity(e, "Tokyo")}
//             >
//               Delivered
//             </button>
//             <button
//               className={`tablinks ${activeTab === "Cancelled" ? "active" : ""}`}
//               onClick={(e) => openCity(e, "Cancelled")}
//             >
//               Cancelled
//             </button>
//           </div>
//           <div id="London" className={`tabcontent ${activeTab === "London" ? "active" : ""}`}>
//             <div className="product-bg-main">
//               <div className="inprogress-flex">
//                 <div className="inprogress-box-one">
//                   <button className="in-progress">In progress</button>
//                 </div>
//                 <div className="inprogress-box-two">
//                   <span className="date-progress">13 June 2024</span>
//                 </div>
//               </div>
//               <div className="order-deatil-flex">
//                 <div className="order-deatil-bx-left">
//                   <span className="order-img" />
//                 </div>
//                 <div className="order-deatil-bx-right">
//                   <h5 className="order-id">Order ID: abc-123</h5>
//                   <p className="product-content">
//                     Lorem Ipsum&nbsp;is simply dummy text of the printing
//                   </p>
//                   <span className="price-product">$50.40</span>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div id="Paris" className={`tabcontent ${activeTab === "Paris" ? "active" : ""}`}>
//             <div className="product-bg-main">
//               <div className="inprogress-flex">
//                 <div className="inprogress-box-one">
//                   <button className="in-progress">In progress</button>
//                 </div>
//                 <div className="inprogress-box-two">
//                   <span className="date-progress">13 June 2024</span>
//                 </div>
//               </div>
//               <div className="order-deatil-flex">
//                 <div className="order-deatil-bx-left">
//                   <span className="order-img" />
//                 </div>
//                 <div className="order-deatil-bx-right">
//                   <h5 className="order-id">Order ID: abc-123</h5>
//                   <p className="product-content">
//                     Lorem Ipsum&nbsp;is simply dummy text of the printing
//                   </p>
//                   <span className="price-product">$50.40</span>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div id="Tokyo" className={`tabcontent ${activeTab === "Tokyo" ? "active" : ""}`}>
//             <div className="product-bg-main">
//               <div className="inprogress-flex">
//                 <div className="inprogress-box-one">
//                   <button className="in-progress">In progress</button>
//                 </div>
//                 <div className="inprogress-box-two">
//                   <span className="date-progress">13 June 2024</span>
//                 </div>
//               </div>
//               <div className="order-deatil-flex">
//                 <div className="order-deatil-bx-left">
//                   <span className="order-img" />
//                 </div>
//                 <div className="order-deatil-bx-right">
//                   <h5 className="order-id">Order ID: abc-123</h5>
//                   <p className="product-content">
//                     Lorem Ipsum&nbsp;is simply dummy text of the printing
//                   </p>
//                   <span className="price-product">$50.40</span>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div id="Cancelled" className={`tabcontent ${activeTab === "Cancelled" ? "active" : ""}`}>
//             {/* Content for Cancelled orders */}
//           </div>
//         </div>
//       </section>
//     </div>
//   );
// }

import React, { useState, useEffect } from "react";
import CustomerProfile from "./CustomerProfile";
import profileIcon from "../../assets/img/profile-ic.png";
import "./CustomerProfile.css";
import Spinner from "react-bootstrap/Spinner";
import { Link, useNavigate } from "react-router-dom";
import { GetAllOrder } from "../customerDashboardApi/PostApi";
const url = process.env.REACT_APP_BASE_URL;
export default function OrderList() {
  const userToken = localStorage.getItem("userToken");
  const Navigate = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem("userToken");
    Navigate("/");
    setIsDropdownOpen(false);
  };
  const [activeTab, setActiveTab] = useState("London");
  const [customerId, setCustomerId] = useState(null);
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const openCity = (event, cityName) => {
    setActiveTab(cityName);
  };
  useEffect(() => {
    const customerId = localStorage.getItem("customerId");
    setCustomerId(customerId);
  }, []);

  useEffect(() => {
    if (!customerId) return;

    const fetchOrders = async () => {
      setLoading(true);
      let queryParam = "";
      switch (activeTab) {
        case "London":
          queryParam = "allOrder";
          break;
        case "Paris":
          queryParam = "inProgress";
          break;
        case "Tokyo":
          queryParam = "delivered";
          break;
        case "Cancelled":
          queryParam = "cancelled";
          break;
        default:
          queryParam = "allOrder";
      }

      try {
        const response = await GetAllOrder(customerId, queryParam);
        if (response?.filteredOrders) {
          setOrders(response.filteredOrders);
        } else {
          console.error("Invalid response format:", response);
        }
      } catch (error) {
        console.error("Error fetching orders:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, [activeTab, customerId]);
  if (loading) {
    return (
      <div>
        <CustomerProfile />
        <section className="home">
          <div className="main-header-tag-title">
            <div className="Side-bar-center">Sales Dashboard</div>
            <div className="profile-icon">
              <a href="#">
                <img
                  src={profileIcon}
                  className="person-image"
                  alt="profile-icon"
                />
              </a>
            </div>
          </div>
        </section>
        <section className="order-list-main">
          <div className="container1">
            <Spinner
              animation="border"
              role="status"
              className="loading-spinner"
            >
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        </section>
      </div>
    );
  }

  return (
    <div>
      <CustomerProfile />
      <section className="home">
        <div className="main-header-tag-title">
          <div className="Side-bar-center">Sales Dashboard</div>
          <div className="profile-icon">
            <img
              src={profileIcon}
              className="person-image"
              alt="profile-icon"
              onClick={toggleDropdown}
              style={{ cursor: "pointer" }}
            />
             {isDropdownOpen &&
              userToken && (
                <div className="dropdown-menu">
                  <button className="logout-button" onClick={handleLogout}>
                    Logout
                  </button>
                </div>
              )}
          </div>
        </div>
      </section>
      <section className="order-list-main">
        <div className="container">
          <div className="tab">
            <button
              className={`tablinks ${activeTab === "London" ? "active" : ""}`}
              onClick={(e) => openCity(e, "London")}
            >
              All
            </button>
            <button
              className={`tablinks ${activeTab === "Paris" ? "active" : ""}`}
              onClick={(e) => openCity(e, "Paris")}
            >
              In Progress
            </button>
            <button
              className={`tablinks ${activeTab === "Tokyo" ? "active" : ""}`}
              onClick={(e) => openCity(e, "Tokyo")}
            >
              Delivered
            </button>
            <button
              className={`tablinks ${
                activeTab === "Cancelled" ? "active" : ""
              }`}
              onClick={(e) => openCity(e, "Cancelled")}
            >
              Cancelled
            </button>
          </div>
          <div
            id="London"
            className={`tabcontent ${activeTab === "London" ? "active" : ""}`}
          >
            {orders?.map((order) => (
              <Link
                to={`/admin/orderDetails?orderId=${order._id}`}
                key={order._id}
              >
                <div className="product-bg-main" key={order._id}>
                  <div className="inprogress-flex">
                    <div className="inprogress-box-one">
                      <button className="in-progress">
                        {order.deliveryStatus}
                      </button>
                    </div>
                    <div className="inprogress-box-two">
                      <span className="date-progress">
                        {new Date(order.orderDate).toLocaleDateString()}
                      </span>
                    </div>
                  </div>
                  <div className="order-deatil-flex">
                    <div className="order-deatil-bx-left">
                      {/* Display the product image from the order's productDetails */}
                      {order.productDetails[0]?.productInfo?.productImage && (
                        <img
                          src={order.productDetails[0].productInfo.productImage}
                          alt={order.productDetails[0].productInfo.productTitle}
                          style={{ height: "100px", width: "50%" }}
                          className="order-img"
                        />
                      )}
                    </div>
                    <div className="order-deatil-bx-right">
                      <h5 className="order-id">Order ID: {order._id}</h5>
                      <p className="product-content">
                        {
                          order.productDetails[0]?.productInfo
                            ?.productDescription
                        }
                      </p>
                      <span className="price-product">
                        {order.productDetails[0]?.productInfo?.productPrice}
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            ))}
          </div>
          {/* Similar structure for other tabs */}
        </div>
      </section>
    </div>
  );
}
